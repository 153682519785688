import { http } from "../../http";
const RegisterPhysician = async (data: any) => {
  return await http.post(`/physician/create`, data);
};
const UpdatePhysician = async (id: any, data: any) => {
  return await http.post(`/physician/update/${id}`, data);
};
const getPhysician = async (id: any) => {
  return await http.get(`/physician/${id}`);
};
const downloadData = async (file_name:any) => {
  return await http.get(`multimedia/downloadcv?file_name=${file_name}`);
};
const notInterestedList = async (id: any) => {
  return await http.get(`physician/not-interested/list/${id}`);
};
const notInterested = async (data: any) => {
  return await http.post(`physician/not-interested/name`, data);
};
const notInterestedDelete = async (data: any) => {
  return await http.post(`physician/not-interested/delete`, data);
};
const playVideo = async (data: any) => {
  return await http.post(`multimedia/play`, data);
};
const downloadFile = async (data: any) => {
  return await http.post(`multimedia/downloadFile`, data);
};

export {
  RegisterPhysician,
  UpdatePhysician,
  getPhysician,
  downloadData,
  notInterestedList,
  notInterested,
  notInterestedDelete,
  playVideo,
  downloadFile
};
