import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MianPage from "./Pages/MianPage/MainPage";
import Registration from "./Pages/Physician/PhysicianAuth/Registration/Registration";
import TraineeRegistration from "./Pages/Trainee/TraineeAuth/Registration/TraineeRegistration";
import TraineeProfile from "./Pages/Trainee/TraineeProfile/TraineeProfile";
import Login from "./Pages/Login/Login";
import PhysicianProfile from "./Pages/Physician/PhysicianProfile/PhysicianProfile";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MianPage />} />
        <Route path="/physician" element={<Registration />} />
        <Route path="/physicianLogin" element={<Login />} />
        <Route path="/trainee" element={<TraineeRegistration />} />
        <Route path="/login" element={<Login />} />
        <Route path="/traineeprofile" element={<TraineeProfile />} />
        <Route path="/physicianprofile" element={<PhysicianProfile />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
