/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Table,
  TableProps,
  Tooltip,
} from "antd";
import { DeleteFilled, DownloadOutlined, EyeFilled, PlayCircleFilled } from "@ant-design/icons";
import "../../../Assets/Profile.css";
import { TraineeGetData } from "../../../Actions/Trainee/TraineeActions";
import {
  UpdatePhysician,
  downloadData,
  downloadFile,
  getPhysician,
  notInterested,
  notInterestedDelete,
  notInterestedList,
  playVideo,
} from "../../../Actions/Physician/PhysicianActions";
import { LogoutUser } from "../../../Actions/AuthLogin/Login";
import { OpenNotification } from "../../../Actions/Utilities/Utilities";
import fileDownload from "js-file-download";

export default function PhysicianProfile() {
  const [data, setData] = React.useState<any>();
  const [traineeList, setTraineeList] = React.useState<any>();
  const [notInterestedLists, setNotInterested] = React.useState<any>();
  const [newValues, setNewValues] = React.useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [videoFile, setVideoFile] = useState<any>();
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [form] = Form.useForm();
  const id = localStorage.getItem("id");
  const role = localStorage.getItem("role");

  const showModal = () => {
    notInterestedList(id).then(({ data: response }) => {
      if (response.success === true) {
        setNotInterested(response.users);
      }
    });
    setIsModalOpen2(true);
  };

  const handleOk = () => {
    setIsModalOpen2(false);
  };
  const handleOk2 = () => {
    setIsModalOpen3(false);
  };

  const handleCancel = () => {
    setIsModalOpen2(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen3(false);
  };
  const handleEdit = () => {
    setIsModalOpen(true);
  };
  const handleCloseModel = () => {
    setIsModalOpen(false);
  };
  const handleDownload = (url: any, filename: any) => {
    // const fileDownload = require('js-file-download');
    fileDownload(url, filename);
  };
  const onFinish = (values: any) => {
    const id = localStorage.getItem("id");
    // console.log(newValues);
    UpdatePhysician(id, values).then(({ data: response }) => {
      if (response.success === true) {
        setData(response.data);
        setIsModalOpen(false);
      }
      // console.log(response);
    });
  };
  const handleCange = (e: any) => {
    const value = e.target.value;
    setNewValues({ ...newValues, [e.target.name]: value });
  };
  const handleLogout = () => {
    
    if (id !== null) {
      LogoutUser().then(({ data: response }) => {
        localStorage.removeItem("id");
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        OpenNotification("success", response.message);
        window.location.href = "/";
      });
    }
  };
  function traineeData() {
    const values = new FormData();
    if (id !== null) {
      values.append("id", id);
      TraineeGetData(values).then(({ data: response }) => {
        setTraineeList(response.data);
         console.log(response)
      });
    }
  }
  useEffect(() => {
    traineeData()
    if (id !== null) {
      getPhysician(id).then(({ data: response }) => {
        setData(response.data);
        // console.log(response)
      });
    }
  }, [id]);
 
  const handleNotInterested = (record: any) => {
    const data = { physician_id: id, user_id: record.id };
    notInterested(data).then(({ data: response }) => {
      console.log(response);
      setTraineeList(response.users);
    });
  };
  const handleNotInterestedDelete = (record: any) => {
    const data = { physician_id: id, user_id: record.id };
    notInterestedDelete(data).then(({ data: response }) => {
      console.log(response);
      setNotInterested(response.users);
      traineeData()
    });
  };
 const  handleDownloadFile = (record:any,fileType:string)=> {
  const userId = {'user_id':record.id,'file_type':fileType}
  downloadFile(userId).then(({data:response})=>{
    const link = document.createElement('a');
    link.href = response;
    link.setAttribute('download', 'recording.mp4');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  })
 }
 const  handlePlay = (record:any)=> {
  const userId = {'user_id':record.id}
  playVideo(userId).then(({data:response})=>{
    console.log(response);
    if (response.success === true) {
      setIsModalOpen3(true)
      setVideoFile(response.video_path)
    }
    
  })
 }
  const columns = [
    {
      title: "Sr",
      dataIndex: "id",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Address",
      dataIndex: "addressLine",
    },
    {
      title: "Action",
      dataIndex: "btn",
      key: "action",
      filters: [
        {
          text: 'CV',
          value: 'cv',
        },
        {
          text: 'Video',
          value: 'video',
        },
        
      ],
      filterSearch: true,
      onFilter: (value: any, record:any) => value === 'video'?record.video:record.cv,
      render: (
        _: any,
        record: { id: React.SetStateAction<null>; cv: any; video: any }
      ) =>
        traineeList.length >= 1 ? (
          <>
            <Space size="middle">
              <Tooltip title="Not Interested" color="red">
                <DeleteFilled
                  className="text-danger"
                  onClick={() => handleNotInterested(record)}
                />
              </Tooltip>
              {record.cv !== "" && (
                <>
                  {/* <Link to={`http://127.0.0.1:8000/multimedia/${record.cv}`} download> */}
                  <Tooltip title="Download CV" color={"#2db7f5"}>
                    <DownloadOutlined
                      className="text-primary"
                      onClick={() => handleDownloadFile(record,'cv')}
                    />
                  </Tooltip>
                  {/* </Link> */}
                </>
              )}
              {record.video !== "" && (
                <>
                  {/* <Link to={`http://127.0.0.1:8000/multimedia/${record.video}`} target="_blank" download> */}
                  <Tooltip title="Download Video" color={"#2db7f5"}>
                    <DownloadOutlined
                      className="text-warning"
                      onClick={() => handleDownloadFile(record,'video')}

                    />
                  </Tooltip>
                  {/*  </Link>*/}
                </>
              )}
              {record.video !== "" && (
                <>
                  {/* <Link to={`http://127.0.0.1:8000/multimedia/${record.video}`} target="_blank" download> */}
                  <Tooltip title="Play Video" color={"#2db7f5"}>
                    <PlayCircleFilled
                      className="text-success"
                      onClick={()=>handlePlay(record)}
                    />
                  </Tooltip>
                  {/*  </Link>*/}
                </>
              )}
            </Space>
          </>
        ) : null,
    },
  ];
  const columns2 = [
    {
      title: "Sr",
      dataIndex: "id",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Address",
      dataIndex: "addressLine",
    },
    {
      title: "Action",
      dataIndex: "btn",
      key: "action",
      render: (
        _: any,
        record: { id: React.SetStateAction<null>; cv: any; video: any }
      ) =>
        traineeList.length >= 1 ? (
          <>
            <Space size="middle">
              <Tooltip title="Not Interested" color="red">
                <DeleteFilled
                  className="text-danger"
                  onClick={() => handleNotInterestedDelete(record)}
                />
              </Tooltip>
              {record.cv !== "" && (
                <>
                  {/* <Link to={`http://127.0.0.1:8000/multimedia/${record.cv}`} download> */}
                  <Tooltip title="Download CV" color={"#2db7f5"}>
                    <DownloadOutlined
                      className="text-primary"
                      onClick={() => handleDownloadFile(record,'cv')}
                    />
                  </Tooltip>
                  {/* </Link> */}
                </>
              )}
              {record.video !== "" && (
                <>
                  {/* <Link to={`http://127.0.0.1:8000/multimedia/${record.video}`} target="_blank" download> */}
                  <Tooltip title="Download Video" color={"#2db7f5"}>
                    <DownloadOutlined
                      className="text-warning"
                      onClick={() => handleDownloadFile(record,'video')}
                    />
                  </Tooltip>
                  {/*  </Link>*/}
                </>
              )}
              {record.video !== "" && (
                <>
                  {/* <Link to={`http://127.0.0.1:8000/multimedia/${record.video}`} target="_blank" download> */}
                  <Tooltip title="Play Video" color={"#2db7f5"}>
                    <PlayCircleFilled
                      className="text-success"
                      onClick={()=>handlePlay(record)}
                    />
                  </Tooltip>
                  {/*  </Link>*/}
                </>
              )}
              
            </Space>
          </>
        ) : null,
    },
  ];
  const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };
  return (
    <>
      {role === "1" && (
        <>
          <div className="container mt-5">
            <Row className="row gutters-sm">
              <Col md={4} lg={6} className="col-md-4">
                <Card className="card">
                  <div className="d-flex flex-column align-items-center text-center">
                    <img
                      src="https://bootdey.com/img/Content/avatar/avatar7.png"
                      alt="Admin"
                      className="rounded-circle"
                      width="150"
                    />
                    <div>
                      <h4>{data?.name}</h4>
                      {/* <p className="text-secondary mb-1">Full Stack Developer</p> */}
                      <p className="text-muted font-size-sm">
                        {data?.role == 2 ? "Trainee" : "Physician"}
                      </p>
                      <Button
                        type="primary"
                        style={{ marginRight: "5px" }}
                        onClick={handleEdit}
                      >
                        Edit
                      </Button>
                      <Button type="primary" onClick={handleLogout}>
                        Logout
                      </Button>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col lg={8} md={8} className="col-md-8">
                <Card className="card mb-3">
                  <Row className="row">
                    <Col sm={6} lg={3}>
                      <h6 className="mb-0">Full Name</h6>
                    </Col>
                    <Col sm={6} lg={9} className=" text-secondary">
                      {data?.name}
                    </Col>
                    <Col sm={6} lg={3}>
                      <h6 className="mb-0">Contact Phone</h6>
                    </Col>
                    <Col sm={6} lg={9} className=" text-secondary">
                      {data?.contactPhone}
                    </Col>
                  </Row>
                  <hr />
                  <Row className="row">
                    <Col sm={6} lg={3}>
                      <h6 className="mb-0">Email</h6>
                    </Col>
                    <Col sm={6} lg={9} className=" text-secondary">
                      {data?.email}
                    </Col>
                    <Col sm={6} lg={3}>
                      <h6 className="mb-0">Contact Name</h6>
                    </Col>
                    <Col sm={6} lg={9} className=" text-secondary">
                      {data?.contactName}
                    </Col>
                  </Row>
                  <hr />
                  <Row className="row">
                    <Col sm={6} lg={3}>
                      <h6 className="mb-0">Phone</h6>
                    </Col>
                    <Col sm={6} lg={9} className=" text-secondary">
                      {data?.phone}
                    </Col>
                    <Col sm={6} lg={3}>
                      <h6 className="mb-0">State</h6>
                    </Col>
                    <Col sm={6} lg={9} className=" text-secondary">
                      {data?.state}
                    </Col>
                  </Row>
                  <hr />
                  <Row className="row">
                    <Col sm={6} lg={3}>
                      <h6 className="mb-0">City</h6>
                    </Col>
                    <Col sm={6} lg={9} className=" text-secondary">
                      {data?.city}
                    </Col>
                    <Col sm={6} lg={3}>
                      <h6 className="mb-0">Zip Code</h6>
                    </Col>
                    <Col sm={6} lg={9} className=" text-secondary">
                      {data?.zipCode}
                    </Col>
                  </Row>
                  <hr />
                  <Row className="row">
                    <Col sm={6} lg={3}>
                      <h6 className="mb-0">Address</h6>
                    </Col>
                    <Col sm={6} lg={9} className=" text-secondary text-nowrap">
                      {data?.addressLine}
                    </Col>
                  </Row>
                  <hr />
                </Card>
              </Col>
            </Row>

            <Row className="row gutters-sm">
              <Col md={24} lg={24} className="col-md-12">
                <Button type="primary" className="mb-2" onClick={showModal}>
                  Not interested list
                </Button>
                <Card className="card mb-3">
                  <Table
                    columns={columns}
                    dataSource={traineeList}
                    onChange={onChange}
                    size="small"
                  />
                </Card>
              </Col>
            </Row>
          </div>
          <Modal
            title="Not Interested Trainees"
            open={isModalOpen2}
            width={1000}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Table
              columns={columns2}
              dataSource={notInterestedLists}
              size="small"
            />
          </Modal>
          <Modal
            title="Video player"
            open={isModalOpen3}
            width={700}
            onOk={handleOk2}
            onCancel={handleCancel2}
          >
            <center>
           <video width="600" controls >
              <source src={videoFile}/>
              </video></center>
          </Modal>
          <Modal
            title="Update Physician"
            open={isModalOpen}
            closable={false}
            footer={false}
            style={{ zIndex: "1050" }}
          >
            <Row justify="space-around" align="middle">
              <Col span={24}>
                {/* <Card title="Sign Up" className="text-center"> */}
                <Form
                  layout="vertical"
                  form={form}
                  name="register"
                  onFinish={onFinish}
                  initialValues={{
                    residence: ["zhejiang", "hangzhou", "xihu"],
                    prefix: "86",
                  }}
                  scrollToFirstError
                >
                  <Row gutter={6}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item name="firstName" label="Practice First Name">
                        <Input
                          defaultValue={data?.firstName}
                          onChange={handleCange}
                          name="firstName"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item name="lastName" label="Practice Last Name">
                        <Input
                          defaultValue={data?.lastName}
                          onChange={handleCange}
                          name="lastName"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={6}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item
                        name="addressLine"
                        label="Practice Address Line"
                      >
                        <Input
                          defaultValue={data?.addressLine}
                          onChange={handleCange}
                          name="addressLine"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item name="email" label="Email Address">
                        <Input
                          defaultValue={data?.email}
                          onChange={handleCange}
                          name="email"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={6}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item name="city" label="City">
                        <Input
                          defaultValue={data?.city}
                          onChange={handleCange}
                          name="city"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item name="state" label="State">
                        <Input
                          defaultValue={data?.state}
                          onChange={handleCange}
                          name="state"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={6}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item name="zipCode" label="Zip Code">
                        <Input
                          defaultValue={data?.zipCode}
                          onChange={handleCange}
                          name="zipCode"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item name="phone" label="Practice Phone">
                        <Input
                          defaultValue={data?.phone}
                          onChange={handleCange}
                          name="phone"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={6}>
                    <Col span={12}>
                      <Form.Item name="website" label="Website">
                        <Input
                          defaultValue={data?.website}
                          onChange={handleCange}
                          name="website"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="password" label="Password">
                        <Input.Password
                          onChange={handleCange}
                          name="password"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={6}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item name="contactName" label="Contact Person Name">
                        <Input
                          defaultValue={data?.contactName}
                          onChange={handleCange}
                          name="contactName"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item
                        name="contactNumber"
                        label="Contact Person Number"
                      >
                        <Input
                          defaultValue={data?.contactPhone}
                          onChange={handleCange}
                          name="contactNumber"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* <Row >
                <Col span={24} >
                  <Form.Item
                    name="hire"
                    label='How soon would you like to hire?'
                    rules={[
                      { required: true, message: "Please select any one of them!" },
                    ]}>
                    <span style={{marginRight:'20px'}}></span>
                    <Radio.Group >
                      <Radio value="Immediately">Immediately</Radio>
                      <Radio value="in 3 months">in 3 months</Radio>
                      <Radio value="in 6 months">in 6 months</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row> */}

                  <Form.Item>
                    <Button
                      type="primary"
                      shape="round"
                      style={{ marginRight: "5px" }}
                      onClick={handleCloseModel}
                    >
                      Cancel
                    </Button>
                    <Button type="primary" shape="round" htmlType="submit">
                      Update
                    </Button>
                  </Form.Item>
                </Form>
                {/* </Card> */}
              </Col>
            </Row>
          </Modal>
        </>
      )}
    </>
  );
}
