import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import Animations from "./Animations/Animation";
// import { Container } from './styles';
const MianPage: React.FC = () => {
  return (
    <>
      <Animations />
      <div className="container-fluid">
        <div className="row ">
          <div
            className="col-lg-6 text-center "
            style={{
              marginTop: "40vh",
            }}
          >
            <h5 className="text-light ">
            I am a Primary Care Physician interested in hiring Trainee Physician
            </h5>
            <br />
            <Link to={"/physician"} style={{ textDecoration: "none" }}>
              <Button type='primary' className="text-light">
                Register to hire
              </Button>
            </Link>

            <br />
            <br />
            <Link to={"/login"} style={{ textDecoration: "none" }}>
              <Button type='text'  className="text-light">
                <small>Sign in here</small>
              </Button>
            </Link>
          </div>
          <div
            className="col-lg-6 text-center"
            style={{
              marginTop: "40vh",
            }}
          >
            <h5 className="text-light">I want to be a Trainee Physician</h5>
            <br />
            <Link to={"/trainee"} style={{ textDecoration: "none" }}>
              <Button type='primary' className="text-light">
              Register to get hired
              </Button>
            </Link>

            <br />
            <br />
            <Link to={"/login"} style={{ textDecoration: "none" }}>
              <Button type='text' className="text-light">
                <small>Sign in here</small>
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default MianPage;
