/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { Button, Card, Col, Form, Input, Radio, Row } from "antd";
import '../../../../Assets/Index.css'
import Animations from './../../../MianPage/Animations/Animation';
import { RegisterPhysician } from "../../../../Actions/Physician/PhysicianActions";
import { OpenNotification } from "../../../../Actions/Utilities/Utilities";
import { useNavigate } from "react-router-dom";

export default function Registration() {

  const navigate = useNavigate()
  const [form] = Form.useForm();


  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
    const data = new FormData();
    data.append("practiceName", values.practiceName);
    data.append("firstName", values.physicianFirstName);
    data.append("lastName", values.physicianLastName);
    data.append("email", values.email);
    data.append("password", values.password);
    data.append("addressLine", values.practiceAddressLine);
    data.append("city", values.city);
    data.append("state", values.state);
    data.append("zipCode", values.zipCode);
    data.append("phone", values.practicePhone);
    data.append("website", values.website);
    data.append("contactName", values.contactPersonName);
    data.append("contactPhone", values.contactPhoneNumber);
    data.append("hire", values.hire);
    RegisterPhysician(data).then(({ data: response }) => {
      if (response.success === true) {
        navigate('/login')
        OpenNotification("success", response.message);
      } else {
        OpenNotification("error", response.message);
      }
    });
  };
  const phoneNumberValidator = (_: any, value: string) => {
    if (value && !/^\d{3}-\d{3}-\d{4}$/.test(value)) {
      return Promise.reject(new Error('Please enter a valid phone number in the format 467-186-4578'));
    }
    return Promise.resolve();
  };
  return (
    <>
      <Animations />

      <Row justify="space-around" align="middle" style={{ marginTop: "5vh" }}>
        <Col span={8}>
          <Card title="Sign Up" className="text-center">
            <Form
              layout="vertical"
              form={form}
              name="register"
              onFinish={onFinish}
              scrollToFirstError
            >
              <Row gutter={6}>
                <Col xs={24} sm={24} md={24} lg={12} xl={24} >
                  <Form.Item
                    name="practiceName"
                    label="Practice Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your First Name!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={6}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} >
                  <Form.Item
                    name="physicianFirstName"
                    label="Physician First Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your First Name!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="physicianLastName"
                    label="Physician Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Last Name!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={6}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={6}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="practiceAddressLine"
                    label="Practice Address Line"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Practice Address Line!",
                      },
                      {
                        message: 'Only letters and numbers are allowed',
                        validator: (_, value) => {
                          if (/^[a-zA-Z0-9]+$/.test(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject('Some message here');
                          }
                         }
                       }
                    ]}
                    hasFeedback
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="email"
                    label="Email Address"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Email Address!",
                      },
                      
                    ]}
                    hasFeedback
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={6}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="city"
                    label="City"
                    rules={[
                      {
                        required: true,
                        message: "Please input your City!",
                      },
                      {
                        message: 'Only Capital letters are allowed',
                        validator: (_, value) => {
                          if (/^[A-Z]+$/.test(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject('Some message here');
                          }
                         }
                       }
                    ]}
                    hasFeedback
                  >
                    <Input className='text-uppercase' />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="state"
                    label="State"
                    rules={[
                      {
                        required: true,
                        message: "Please input your State!",
                      },
                      {
                        message: 'Only Capital letters are allowed',
                        validator: (_, value) => {
                          if (/^[A-Z]+$/.test(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject('Some message here');
                          }
                         }
                       }
                    ]}
                    hasFeedback
                  >
                    <Input maxLength={2} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={6}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="zipCode"
                    label="Zip Code"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Zip Code!",
                      },
                      {
                        message: 'Only numbers are allowed',
                        validator: (_, value) => {
                          if (/^[0-9]+$/.test(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject('Some message here');
                          }
                         }
                       }
                    ]}
                    hasFeedback
                  >
                    <Input maxLength={5}/>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="practicePhone"
                    label="Practice Phone"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Practice Phone!",
                      },
                      {
                        validator: phoneNumberValidator,
                      },
                    ]}
                    hasFeedback
                  >
                    <Input maxLength={12}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={6}>
                <Col span={24}>
                  <Form.Item name="website" label="Website" hasFeedback>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={6}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="contactPersonName"
                    label="Contact Person Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Contact Person Name!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="contactPhoneNumber"
                    label="Contact Person Number"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Contact Person Number!",
                      },
                      {
                        validator: phoneNumberValidator,
                      },
                    ]}
                    hasFeedback
                  >
                    <Input maxLength={12}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row >
                <Col span={24} >
                  <Form.Item
                    name="hire"
                    label='How soon would you like to hire?'
                    rules={[
                      { required: true, message: "Please select any one of them!" },
                    ]}>
                    {/* <span style={{marginRight:'20px'}}></span> */}
                    <Radio.Group >
                      <Radio value="Immediately">Immediately</Radio>
                      <Radio value="in 3 months">in 3 months</Radio>
                      <Radio value="in 6 months">in 6 months</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item >
                <Button type="primary" shape='round' size='large' block htmlType="submit">
                  Register
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      
    </>
  );
}
