/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";

import { Row, Col, Form, Radio, Button, Input, Card, DatePicker, DatePickerProps } from "antd";
import { Select } from "antd";
import '../../../../Assets/Index.css'
import Animations from './../../../MianPage/Animations/Animation';
import { RegisterTrainee } from "../../../../Actions/Trainee/TraineeActions";
import { OpenNotification } from "../../../../Actions/Utilities/Utilities";
import { useNavigate } from "react-router-dom";
export default function TraineeRegistration() {
 
  // const [data, setData] = React.useState<any>({});
  const [dob, setDob] = React.useState<string>('');
  const [form] = Form.useForm();
  
  const { Option } = Select;
  const navigate = useNavigate()


  const onFinish = (values: any) => {
    const data = new FormData();
    data.append("firstName", values.firstName);
    data.append("lastName", values.lastName);
    data.append("email", values.email);
    data.append("password", values.password);
    data.append("dob", dob);
    data.append("gender", values.gender);
    data.append("addressLine", values.addressLine);
    data.append("city", values.city);
    data.append("state", values.state);
    data.append("zipCode", values.zipCode);
    data.append("phone", values.phone);
    data.append("USMLE", values.USMLE);
    data.append("relocate", values.relocate);
    data.append("publicTransport", values.publicTransport);
    data.append("ruralArizona", values.ruralArizona);
    console.log(data)
    RegisterTrainee(data).then(({ data: response }) => {
      if (response.success === true) {
        console.log(response);
        navigate('/login');
        OpenNotification("success", response.message);
      } else {
        console.log(response);
        OpenNotification("error", response.message);
      }
    });

    // console.log("Received values of form: ", values);
  };
  const onChangeDob: DatePickerProps['onChange'] = (date, dateString) => {
    setDob(dateString);
  };
  const phoneNumberValidator = (_: any, value: string) => {
    if (value && !/^\d{3}-\d{3}-\d{4}$/.test(value)) {
      return Promise.reject(new Error('Please enter a valid phone number in the format 467-186-4578'));
    }
    return Promise.resolve();
  };
  return (
    <>
      <Animations />
      <Row justify="space-around" align="middle" style={{ marginTop: "3vh" }}>
        <Col span={8}>
          <Card title="Sign Up" className="text-center">
            <Form
              layout="vertical"
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={{
                residence: ["zhejiang", "hangzhou", "xihu"],
                prefix: "86",
              }}
              scrollToFirstError
            >
              <Row gutter={6}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your First Name!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Last Name!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item> 
                </Col>
              </Row>
              <Row gutter={6}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="email"
                    label="Email Address"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Email Address!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="dob"
                    label="DOB"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <DatePicker onChange={onChangeDob} style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={6}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={6}>
                
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="gender"
                    label="Gender"
                    rules={[
                      { required: true, message: "Please select gender!" },
                    ]}
                  >
                    <Select placeholder="select your gender">
                      <Option value="Male">Male</Option>
                      <Option value="Female">Female</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="addressLine"
                    label="Address Line"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Address Line!",
                      },
                      {
                        message: 'Only letters and numbers are allowed',
                        validator: (_, value) => {
                          if (/^[a-zA-Z0-9]+$/.test(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject('Some message here');
                          }
                         }
                       }
                    ]}
                    hasFeedback
                  >
                    <Input />
                  </Form.Item>
                </Col>
               
              </Row>
              
              <Row gutter={6}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="city"
                    label="City"
                    rules={[
                      {
                        required: true,
                        message: "Please input your City!",
                      },
                      {
                        message: 'Only Capital letters are allowed',
                        validator: (_, value) => {
                          if (/^[A-Z]+$/.test(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject('Some message here');
                          }
                         }
                       }
                    ]}
                    hasFeedback
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="state"
                    label="State"
                    rules={[
                      {
                        required: true,
                        message: "Please input your State!",
                      },
                      {
                        message: 'Only Capital letters are allowed',
                        validator: (_, value) => {
                          if (/^[A-Z]+$/.test(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject('Some message here');
                          }
                         }
                       }
                    ]}
                    hasFeedback
                  >
                    <Input maxLength={2}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={6}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="zipCode"
                    label="Zip Code"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Zip Code!",
                      },
                      {
                        message: 'Only numbers are allowed',
                        validator: (_, value) => {
                          if (/^[0-9]+$/.test(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject('Some message here');
                          }
                         }
                       }
                    ]}
                    hasFeedback
                  >
                    <Input maxLength={5}/>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="phone"
                    label="Phone"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Phone!",
                      },
                      {
                        validator: phoneNumberValidator,
                      },
                    ]}
                    hasFeedback
                  >
                    <Input maxLength={12}/>
                  </Form.Item>
                </Col>
              </Row>
              

              <Row>
                <Col span={24} >
                  <Form.Item 
                  name="USMLE" 
                  label="Successfully Completed USMLE Steps I & II"
                  rules={[
                    { required: true, message: "Please select USMLE!" },
                  ]} >
                  {/*   <span style={{ float:'left' }} >
                    Successfully Completed USMLE Steps I & II
                    </span> */}
                    <Radio.Group style={{ float:'left',marginLeft:'10px' }} >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24} >
                  <Form.Item 
                  name="relocate" 
                  label="Are you willing to relocate."
                  rules={[
                    { required: true, message: "Please select relocate!" },
                  ]} >
                    {/* <span style={{ float:'left' }} >
                    Are you ready to relocate
                    </span> */}
                    <Radio.Group style={{ float:'left',marginLeft:'10px' }} >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24} >
                  <Form.Item 
                  name="publicTransport" 
                  label="This position requires ability to drive as public transport is not easily available."
                  rules={[
                    { required: true, message: "Please select Public Transport!" },
                  ]} >
                   {/*  <span style={{ float:'left' }} >
                    This position requires ability to drive as public
                      transport is not easity available.
                    </span> */}
                    <Radio.Group style={{ float:'left',marginLeft:'10px' }} >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24} >
                  <Form.Item 
                  name="ruralArizona" 
                  label="This position is in rural Arizona, Please acknowledge."
                  rules={[
                    { required: true, message: "Please select Rural Arizona!" },
                  ]} >
                  {/*   <span style={{ float:'left' }} >
                    This position is in rural Arizona, Please acknowledge.
                    </span> */}
                    <Radio.Group style={{ float:'left',marginLeft:'10px' }} >
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  block
                  htmlType="submit"
                >
                  Register
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      {/* <Container component="main" maxWidth="sm">
        <Card
          style={{ marginTop: "5vh", borderRadius: "10px" }}
          variant="outlined"
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlined />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign up
              </Typography>
              <Box component="form" noValidate sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      onChange={(e) => handleChange(e)}
                      size="small"
                      autoComplete="given-name"
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      onChange={(e) => handleChange(e)}
                      size="small"
                      autoComplete="given-name"
                      name="lastName"
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      autoFocus
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={(e) => handleChange(e)}
                      size="small"
                      required
                      fullWidth
                      type="email"
                      name="email"
                      label="Email Address"
                      id="Email Address"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="DOB"
                        format="YYYY-MM-DD"
                        slotProps={{ textField: { size: 'small' } }}
                        onChange={(e) => handleDobChange(e)}
                        />
                    </LocalizationProvider>
                    
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={(e) => handleChange(e)}
                      size="small"
                      required
                      fullWidth
                      name="Phone"
                      label=" Phone #"
                      id=" Phone #"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={(e) => handleChange(e)}
                      size="small"
                      required
                      fullWidth
                      id=" Address Line"
                      label=" Address Line"
                      name="AddressLine"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={(e) => handleChange(e)}
                      size="small"
                      required
                      fullWidth
                      id="City"
                      label="City"
                      name="city"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={(e) => handleChange(e)}
                      size="small"
                      required
                      fullWidth
                      name="state"
                      label="State"
                      id="State"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      onChange={(e) => handleChange(e)}
                      size="small"
                      required
                      fullWidth
                      name="zipCode"
                      label="Zip Code"
                      id="Zip Code"
                    />
                  </Grid>

                  

                  <Grid item sm={6}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Gender"
                        name="gender"
                        onChange={handleChange}
                      >
                        <MenuItem value='Male'>Male</MenuItem>
                        <MenuItem value='Female'>Female</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={8}>
                    <h6 className="pt-2">
                      Successfully Completed USMLE Steps I & II
                    </h6>
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl>
                      <RadioGroup
                        onChange={(e) => handleChange(e)}
                        aria-labelledby="demo-customized-radios"
                        row
                        name="USMLE"
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio size="small" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio size="small" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item sm={8}>
                    <h6 className="pt-2">Are you ready to relocate</h6>
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl>
                      <RadioGroup
                        onChange={(e) => handleChange(e)}
                        aria-labelledby="demo-customized-radios"
                        row
                        name="relocate"
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio size="small" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio size="small" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item sm={8}>
                    <h6 className="pt-2">
                      This position requires ability to drive as public
                      transport is not easity available. Please acknowledge.
                    </h6>
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl>
                      <RadioGroup
                        onChange={(e) => handleChange(e)}
                        aria-labelledby="demo-customized-radios"
                        row
                        name="publicTransport"
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio size="small" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio size="small" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item sm={8}>
                    <h6 className="pt-2">
                      This position is in rural Arizona, Please acknowledge.
                    </h6>
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl>
                      <RadioGroup
                        onChange={(e) => handleChange(e)}
                        aria-labelledby="demo-customized-radios"
                        row
                        name="ruralArizona"
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio size="small" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio size="small" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Button
                  onClick={handleFinish}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, borderRadius: "20px" }}
                >
                  Sign Up
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Container> */}
    </>
  );
}
