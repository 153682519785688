import { http } from "../../http";
export type LoginRequest = {
  email: string;
  password: string;
};
const LoginAccount = async (data: LoginRequest) => {
  return await http.post(`/login`, data);
};
const LogoutUser = async () => {
  return await http.post(`/logout`);
};
export { LoginAccount, LogoutUser };
