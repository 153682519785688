import React from 'react';
import './Animation.css'

// import { Container } from './styles';

const Animations: React.FC = () => {
  return (
    <ul className="background">
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
 </ul>
  )
}

export default Animations;