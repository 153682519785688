import React, { useEffect } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  Modal,
  Row,
  Select,
} from "antd";
import "../../../Assets/Profile.css";
import {
  getSingleTrainee,
  updateTrainee,
} from "../../../Actions/Trainee/TraineeActions";
import { LogoutUser } from "../../../Actions/AuthLogin/Login";
import { OpenNotification } from "../../../Actions/Utilities/Utilities";

export default function TraineeProfile() {
  const [data, setData] = React.useState<any>();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cvTitle, setCvTitle] = React.useState<string>("");
  const [dob, setDob] = React.useState<string>("");
  const [cvFile, setCvFile] = React.useState<string>("");
  const [videoTitle, setVideoTitle] = React.useState<string>("");
  const [videoFile, setVideoFile] = React.useState<string>("");
  const id = localStorage.getItem("id");
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const [form] = Form.useForm();
  const { Option } = Select;

  const handleLogout = () => {
    if (id !== null) {
      LogoutUser().then(({ data: response }) => {
        localStorage.removeItem("id");
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        OpenNotification("success", response.message);
        window.location.href = "/";
      });
    }
  };
  const handleEdit = () => {
    form.setFieldsValue(data);
    setIsModalOpen(true);
  };
  const handleCloseModel = () => {
    setIsModalOpen(false);
  };
  const handleCvUpload = () => {
    setLoading(true);
    if (cvTitle !== "" && cvFile !== undefined && id !== null) {
      const data1 = new FormData();
      const xhr = new XMLHttpRequest();
      data1.append("label", cvTitle);
      data1.append("file_name", cvFile);
      data1.append("file_type", "cv");
      data1.append("user_id", id);
      xhr.responseType = "json";
      xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4 && this.status === 200) {
          setLoading(false);
          setCvTitle('')
          OpenNotification("success", "Record Added successfully");
        }
      });
      const mainurl = process.env.REACT_APP_API_URL;
      const url = `${mainurl}/multimedia/create`;
      xhr.open("POST", url);
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      xhr.setRequestHeader("Access-Control-Allow-Origin", `*`);
      xhr.setRequestHeader(
        "access-control-allow-methods",
        `GET, PUT, POST, DELETE, HEAD, OPTIONS`
      );
      if (data1) {
        xhr.send(data1);
      }
    }
    // console.log(cvTitle, cvFile);
  };
  const handleVideoUpload = () => {
    setLoading(true);
    if (videoTitle !== "" && videoFile !== undefined && id !== null) {
      const data2 = new FormData();
      const xhr = new XMLHttpRequest();
      data2.append("label", videoTitle);
      data2.append("file_name", videoFile);
      data2.append("file_type", "video");
      data2.append("user_id", id);
      xhr.responseType = "json";
      xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4 && this.status === 200) {
          setLoading(false);
          setVideoTitle('')
          OpenNotification("success", "Record Added successfully");
        }
      });
      const mainurl = process.env.REACT_APP_API_URL;
      const url = `${mainurl}/multimedia/create`;
      xhr.open("POST", url);
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      xhr.setRequestHeader("Access-Control-Allow-Origin", `*`);
      xhr.setRequestHeader(
        "access-control-allow-methods",
        `GET, PUT, POST, DELETE, HEAD, OPTIONS`
      );
      if (data2) {
        xhr.send(data2);
      }
    }
  };
  const handleCvTitle = (e: any) => {
    setData({ ...data, cvTitle: e.target.value });
    setCvTitle(e.target.value);
  };
  const handleCvFile = (e: any) => {
    setCvFile(e.target.files[0]);
  };
  const handleVideoTitle = (e: any) => {
    setData({ ...data, videoTitle: e.target.value });
    setVideoTitle(e.target.value);
  };
  const handleVideoFile = (e: any) => {
    const file = e.target.files[0];
    if (file && file.size <= 10 * 1024 * 1024) {
      // File size is within the limit, handle as needed
      setVideoFile(file);
    } else {
      OpenNotification("error", 'File exceed the limit of 10MB');
    }
  };
  useEffect(() => {
    if (id !== null) {
      getSingleTrainee(id).then(({ data: response }) => {
        setData(response.data);
      });
    }
  }, []);
  const onFinish = (values: any) => {
    setLoading(true);

    const id = localStorage.getItem("id");
    const data = new FormData();
    data.append("firstName", values.firstName ?? "");
    data.append("lastName", values.lastName ?? "");
    data.append("email", values.email ?? "");
    data.append("password", values.password ?? "");
    data.append("dob", dob ?? "");
    data.append("gender", values.gender ?? "");
    data.append("addressLine", values.addressLine ?? "");
    data.append("city", values.city ?? "");
    data.append("state", values.state ?? "");
    data.append("zipCode", values.zipCode ?? "");
    data.append("phone", values.phone ?? "");
    data.append("USMLE", values.USMLE ?? "");
    data.append("relocate", values.relocate ?? "");
    data.append("publicTransport", values.publicTransport ?? "");
    data.append("ruralArizona", values.ruralArizona ?? "");
    updateTrainee(id, data).then(({ data: response }) => {
      if (response.success === true) {
        setLoading(false);
        setData(response.data);
        setIsModalOpen(false);
      }
    });
  };
  const onChangeDob: DatePickerProps["onChange"] = (date, dateString) => {
    setDob(dateString);
  };

  return (
    <>
      {role === "2" && (
        <>
          <div className="container mt-5">
            <Row className="row gutters-sm">
              <Col md={4} lg={6} className="col-md-4">
                <Card className="card">
                  <div className="d-flex flex-column align-items-center text-center">
                    <img
                      src="https://bootdey.com/img/Content/avatar/avatar7.png"
                      alt="Admin"
                      className="rounded-circle"
                      width="150"
                    />
                    <div>
                      <h4>{data?.name}</h4>
                      {/* <p className="text-secondary mb-1">Full Stack Developer</p> */}
                      <p className="text-muted font-size-sm">
                        {data?.role == 2 ? "Trainee" : "Physician"}
                      </p>
                      <Button
                        type="primary"
                        shape="round"
                        style={{ marginRight: "5px" }}
                        onClick={handleEdit}
                      >
                        Edit
                      </Button>
                      <Button
                        type="primary"
                        shape="round"
                        onClick={handleLogout}
                      >
                        Logout
                      </Button>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col lg={8} md={8} className="col-md-8">
                <Card className="card mb-3">
                  <Row className="row">
                    <Col sm={6} lg={3}>
                      <h6 className="mb-0">Full Name</h6>
                    </Col>
                    <Col sm={6} lg={9} className=" text-secondary">
                      {data?.name}
                    </Col>
                    <Col sm={6} lg={3}>
                      <h6 className="mb-0">Gender</h6>
                    </Col>
                    <Col sm={6} lg={9} className=" text-secondary">
                      {data?.gender}
                    </Col>
                  </Row>
                  <hr />
                  <Row className="row">
                    <Col sm={6} lg={3}>
                      <h6 className="mb-0">Email</h6>
                    </Col>
                    <Col sm={6} lg={9} className=" text-secondary">
                      {data?.email}
                    </Col>
                    <Col sm={6} lg={3}>
                      <h6 className="mb-0">DOB</h6>
                    </Col>
                    <Col sm={6} lg={9} className=" text-secondary">
                      {data?.dob}
                    </Col>
                  </Row>
                  <hr />
                  <Row className="row">
                    <Col sm={6} lg={3}>
                      <h6 className="mb-0">Phone</h6>
                    </Col>
                    <Col sm={6} lg={9} className=" text-secondary">
                      {data?.phone}
                    </Col>
                    <Col sm={6} lg={3}>
                      <h6 className="mb-0">State</h6>
                    </Col>
                    <Col sm={6} lg={9} className=" text-secondary">
                      {data?.state}
                    </Col>
                  </Row>
                  <hr />
                  <Row className="row">
                    <Col sm={6} lg={3}>
                      <h6 className="mb-0">City</h6>
                    </Col>
                    <Col sm={6} lg={9} className=" text-secondary">
                      {data?.city}
                    </Col>
                    <Col sm={6} lg={3}>
                      <h6 className="mb-0">Zip Code</h6>
                    </Col>
                    <Col sm={6} lg={9} className=" text-secondary">
                      {data?.zipCode}
                    </Col>
                  </Row>
                  <hr />
                  <Row className="row">
                    <Col sm={6} lg={3}>
                      <h6 className="mb-0">Address</h6>
                    </Col>
                    <Col sm={6} lg={9} className=" text-secondary text-nowrap">
                      {data?.addressLine}
                    </Col>
                  </Row>
                  <hr />
                </Card>
              </Col>
            </Row>
            <Row className="row gutters-sm">
              <Col md={6} lg={6} className="col-md-6">
                <Card title="For CV" size="small" className="card mb-3">
                  <Row>
                    <Col span={12}>
                      <label htmlFor="">File Title</label>
                      <div>
                        <Input
                          style={{ width: "240px" }}
                          value={data?.cvTitle}
                          onChange={(e: any) => handleCvTitle(e)}
                        />
                      </div>
                    </Col>
                    <Col span={12}>
                      <label htmlFor="">Upload File</label>
                      <div>
                        <input
                          // key={inputKey || ''}
                          type="file"
                          name="note_file"
                          required
                          className="form-control"
                          onChange={(e: any) => handleCvFile(e)}
                          accept=".doc,.docx,application/pdf"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} >
                    
                    </Col>
                    <Col span={12} >
                       
                    <Button
                    type="primary"
                    loading={loading}
                    style={{ float: "right" }}
                    onClick={handleCvUpload}
                  >
                    Upload
                  </Button>
                    </Col>
                   
                  </Row>
                </Card>
              </Col>
              <Col lg={6} md={6} className="col-md-6">
                <Card title="For Video" size="small" className="card mb-3">
                  <Row>
                    <Col span={12}>
                      <label htmlFor="">File Title</label>
                      <div>
                        <Input
                          style={{ width: "240px" }}
                          value={data?.videoTitle}
                          onChange={(e: any) => handleVideoTitle(e)}
                        />
                      </div>
                    </Col>
                    <Col span={12}>
                      <label htmlFor="">Upload File</label>
                      <div>
                        <input
                          // key={inputKey || ''}
                          type="file"
                          className="form-control"
                          name="note_file"
                          accept="video/*"
                          required
                          onChange={(e: any) => handleVideoFile(e)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} >
                    
                    </Col>
                    <Col span={12} >
                       
                  <small className="text-danger">File size should be less than 10MB</small>
                  <Button
                    type="primary"
                    loading={loading}
                    style={{ float: "right" }}
                    onClick={handleVideoUpload}
                  >
                    Upload
                  </Button>
                    </Col>
                   
                  </Row>
                 
                </Card>
              </Col>
            </Row>
          </div>
          <Modal
            title="Update Physician"
            open={isModalOpen}
            closable={false}
            footer={false}
            style={{ zIndex: "1050" }}
          >
            <Row justify="space-around" align="middle">
              <Col span={24}>
                {/* <Card title="Sign Up" className="text-center"> */}
                <Form
                  layout="vertical"
                  form={form}
                  name="register"
                  onFinish={onFinish}
                  initialValues={{
                    residence: ["zhejiang", "hangzhou", "xihu"],
                    prefix: "86",
                  }}
                  scrollToFirstError
                >
                  <Row gutter={6}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item name="firstName" label="First Name">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item name="lastName" label="Last Name">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={6}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item name="email" label="Email Address" hasFeedback>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item name="dob" label="DOB" valuePropName={"date"}>
                        <DatePicker
                          onChange={onChangeDob}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={6}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                        ]}
                        hasFeedback
                      >
                        <Input.Password />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={["password"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "The two passwords that you entered do not match!"
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={6}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item name="gender" label="Gender">
                        <Select placeholder="select your gender">
                          <Option value="Male">Male</Option>
                          <Option value="Female">Female</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item
                        name="addressLine"
                        label="Address Line"
                        hasFeedback
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={6}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item name="city" label="City" hasFeedback>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item name="state" label="State" hasFeedback>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={6}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item name="zipCode" label="Zip Code" hasFeedback>
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item name="phone" label="Phone" hasFeedback>
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item>
                    <Button
                      type="primary"
                      shape="round"
                      style={{ marginRight: "5px" }}
                      onClick={handleCloseModel}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      shape="round"
                      htmlType="submit"
                      loading={loading}
                    >
                      Update
                    </Button>
                  </Form.Item>
                </Form>
                {/* </Card> */}
              </Col>
            </Row>
          </Modal>
        </>
      )}
    </>
  );
}
