import {http} from '../../http';
const RegisterTrainee = async (data:any) => {
    return await http.post(`/trainee/create`,data);
  };
  const TraineeGetData = async (data:any) => {
    return await http.get(`/trainee`,data);
  };
  const getSingleTrainee = async (id:any) => {
    return await http.get(`/trainee/${id}`);
  };
  const updateTrainee = async (id:any,data:any) => {
    return await http.post(`trainee/update/${id}`,data);
  };
  export { RegisterTrainee, TraineeGetData, getSingleTrainee,updateTrainee };