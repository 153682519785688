/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import Animations from "../MianPage/Animations/Animation";
import { Form } from "antd";
import { Input } from "antd";
import { Button } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Card } from "antd";
import { Row } from "antd";
import { Col } from "antd";
import { Link } from "react-router-dom";
import { LoginAccount, LoginRequest } from "../../Actions/AuthLogin/Login";
import { OpenNotification } from "../../Actions/Utilities/Utilities";

export default function Login() {
  // const navigate = useNavigate();
  const [credentials, setCredentials] = React.useState<LoginRequest>({
    email: "",
    password: "",
  });
  const handleChange = (e: { target: { value: any; name: any } }) => {
    const { value } = e.target;
    setCredentials({
      ...credentials,
      [e.target.name]: value,
    });
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();

    LoginAccount(credentials)
      .then(({ data: response }) => {
        console.log(response);

        if (response.success === true) {
          console.log(response);
          localStorage.setItem("token", response.token);
          localStorage.setItem("id", response.Auth_id);
          localStorage.setItem("role", response.Auth_role);
          OpenNotification("success", response.message);
          if (response.Auth_role === 1) {
            window.location.href = "/physicianprofile";
          }
          if (response.Auth_role === 2) {
            window.location.href = "/traineeprofile";
          }
        }
      })
      .catch((error: any) => {
        console.log(error);
        // window.location.reload();
        OpenNotification(
          "error",
          "These credentials do not match our records."
        );
      });
  };

  return (
    <>
      <Animations />
      <Row justify="space-around" align="middle" style={{ marginTop: "30vh" }}>
        <Col span={6}>
          <Card title="Sign In" className="text-center">
            <form
              name="normal_login"
              className="login-form"
              // initialValues={{ remember: true }}
            >
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input your Email!" },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                  allowClear
                  name="email"
                  onChange={(e: any) => handleChange(e)}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                  allowClear
                  name="password"
                  onChange={(e: any) => handleChange(e)}
                />
              </Form.Item>
              <br />

              <Form.Item>
                <Button
                  type="primary"
                  // htmlType="submit"
                  block
                  shape="round"
                  className="login-form-button"
                  onClick={(e) => handleSubmit(e)}
                >
                  Log in
                </Button>
                {/*  Or <a href="">register now!</a> */}
              </Form.Item>
              <div>
                <Link style={{ textDecoration: "none" }} to={"#"}>
                  {"Don't have an account? "}
                </Link>
              </div>
              <Link to={"/trainee"}>{"Sign Up as Trainee. "}</Link>
              <Link to={"/physician"}>{" Sign Up as Physician"}</Link>
            </form>
          </Card>
        </Col>
      </Row>
    </>
  );
}
